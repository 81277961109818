<template>
    <div class="container">
        <header class="jumbotron">
            <strong>Users:</strong>
            <table class="table table-hover" v-if="users">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Unique id</th>
                        <th scope="col">Type id</th>
                        <th scope="col">Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody v-for="user in users.data" :key="user">
                    <tr>
                        <td>
                            {{user.name}}
                        </td>
                        <td>
                            {{user.unique_id}}
                        </td>
                        <td>
                            {{user.type_id}}
                        </td>
                        <td>
                            {{user.email}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </header>
    </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
    name: "User",
    data() {
        return {
            users: "",
            user: {
                unique_id: "",
                name: "",
                type_id: "",
                email: "",
                created_at: "",
                updated_at: "",
            },
        };
    },
    mounted() {
        UserService.getUsersBoard().then(
            (response) => {
                this.users = response.data;
            },
            (error) => {
                this.users =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    },
};
</script>
